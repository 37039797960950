@import "../../../bps/components/carousel/carousel.scss";

.carousel {
  $root: &;

  &__page-info {
    color: $blackColor;
    font-weight: $fontWeightSemiBold;
    font-size: $carouselPaginationFontSizeMobile;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $carouselPaginationFontSize;
    }

    span {
      font-weight: $fontWeightRegular;
    }
  }

  &__button {
    @media (max-width: $screen-mobile-landscape-max) {
      height: 50px;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
    }

    &--next {
      padding-right: 30px;

      @media (min-width: $screen-tablet-portrait) {
        padding-right: 0;
        right: -20px;
        width: 50px;
      }

      @media (min-width: $screen-desktop-xl) {
        right: -55px;
      }
    }

    &--prev {
      padding-left: 30px;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 0;
        left: -20px;
        width: 50px;
      }

      @media (min-width: $screen-desktop-xl) {
        left: -55px;
      }
    }
  }

  &__button-icon {
    transform: translateY(2px);

    #{$root} &::before {
      font-size: 18px;
    }

    @media (min-width: $screen-tablet-portrait) {
      transform: translateY(-50%);
      background: transparent !important;
      box-shadow: none;
      width: 50px;
      height: 50px;
      padding: 5px;

      #{$root} &::before {
        color: $blackColor !important;
        background: $whiteColor !important;
        box-shadow: 0 0 4px 1px rgb(0 0 0 / .1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
      }
    }

    @media (min-width: $screen-desktop-xl) {
      #{$root} &::before {
        font-size: 22px;
      }
    }
  }

  &__pagination-wrapper {
    align-items: baseline;
    margin: 0 0 -13px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }
  }
}
