@import '../../../bps/components/headline/headline.scss';

.headline {
  &--error {
    color: $errorColor;
  }

  &--times-line {
    @include fontSize(26px);
    font-family: $fontSecondaryFamily;
    font-weight: $fontWeightRegular;
    margin: 12px 0;
  }

  &--main-line {
    line-height: 1.2;
    font-weight: $headlineMainLineFontWeight !important;
    font-size: 35px !important;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 36px !important;
    }

    @media (min-width: $screen-laptop) {
      font-size: 40px !important;
    }
  }

  &--sub-line {
    .headline--center & {
      margin-left: auto;
      margin-right: auto;
    }

    .headline--left & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &--big,
  &--extra-big {
    color: $textBaseColor;
  }

  &--font-oswald {
    font-family: $fontPrimaryFamily !important;
  }

  &--font-times {
    font-family: $fontSecondaryFamily !important;
  }

  &--text-light {
    color: $textLightColor;
  }

  &--text-dark {
    color: $textDarkestColor;
  }

  &--text-light-shadow {
    text-shadow: 0 0 5px rgb(255 255 255), 0 0 3px rgb(255 255 255), 0 0 3px rgb(255 255 255);
  }

  &--text-dark-shadow {
    text-shadow: 0 0 5px rgb(0 0 0), 0 0 3px rgb(0 0 0), 0 0 3px rgb(0 0 0);
  }

  &--no-margin {
    margin: 0;
  }

  &--center {
    h2,
    h3 {
      margin: auto;
      line-height: 1.2;
    }
  }

  &--left {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    h2,
    h3 {
      margin: 0;
    }
  }
}
